<template>
    <ul class="section">
        <li v-for="(item,index) in caseList" :key="index" @click="href(item.id)">
            <div class="imgBox">
                <img :src="item.photo" />
            </div>
            <h1>{{item.title}}</h1>
        </li>
    </ul>
</template>
<style  lang="scss" scoped>
    .section{
        width: 12rem;
        margin: .5rem auto;
        display: flex;
        flex-wrap: wrap;
        li{
            width: 33%;
            margin-bottom: .2rem;
            text-align: center;
            cursor: pointer;
            padding: .2rem;
            box-sizing: border-box;
            h1{
                font-size: .16rem;
                text-align: center;
                font-weight: normal;
                padding: .1rem 0;
            }
            .imgBox{
                width: 3.6rem;
                height: 2.5rem;
                overflow: hidden;
                margin: 0 auto;
                img{
                    width: 100%;
                    height: 100%;
                    transition: all 1s;
                }
            }
        }
         li:hover{
             box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
             .imgBox{
                 img{
                     transform:scale(1.5);
                 }
             }
         }

    }
</style>
<script>
import { useRouter } from 'vue-router'
import {onMounted} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
    props:['caseList'],
    setup(){
        onMounted(()=>{
            console.log("ll")
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 100,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
        })
         let router = useRouter();
        function href(id){
            router.push({
            //传递参数使用params的话，只能使用name指定(在route.js里面声明name)
                path:"/caseDetail",
                query:{
                    id:id,
                }
            })
        }
        return{
            href
        }
    }
}
</script>