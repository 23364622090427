<template>
<!-- 轮播图 -->
    <Banner :imgArr="state.arr" v-if="state.arr!=''" />
    <div class="section">
      <Case :caseList="state.caseList"/>
    </div>
</template>
<style  lang="scss" scoped>

</style>
<script>
import {onMounted, reactive} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
import Case from './components/caseCard.vue'
import { getCaseList,getbannerData} from "../../api/request"
export default {
  components: {
        Banner,
        Case
    },
    setup(){
        onMounted(()=>{
            console.log("ll")
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 100,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
            getData()
        })
        const arr = [
            {img: require("@/assets/images/banner/case.jpg"),},
        ];
        let state = reactive({
          arr:[],
          caseList:[]
        })
        // const caseList = [
        //   {id:1,title:"正阳花生物联网项目",fengmian:require('@/assets/images/case/img1.jpg')},
        //   {id:2,title:"农产品溯源体系",fengmian:require('@/assets/images/case/img2.jpg')},
        //   {id:3,title:"安徽爆音智慧农业项目",fengmian:require('@/assets/images/case/img3.jpg')},
        //   {id:4,title:"黄淮学院科研温室",fengmian:require('@/assets/images/case/img1.jpg')},
        //   {id:5,title:"农科院试验田",fengmian:require('@/assets/images/case/img5.jpg')},
        //   {id:6,title:"智慧烟苗",fengmian:require('@/assets/images/case/img1.jpg')},
        //   {id:7,title:"新疆喀什数字订单农业",fengmian:require('@/assets/images/case/img7.jpg')}
        // ]
        function getData(){
          getbannerData({id:38}).then((res)=>{
                if(res.code == 200){
                state.arr = res.data
                }
            })
          getCaseList().then((res) => {
              if(res.code == 200){
                state.caseList = res.data.list
              }
          })
        }
        return{
          arr,
          state,
          getData
        }
    }
}
</script>